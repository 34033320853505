export function randomStringColor(): string {
  return (
    '#' +
    Math.floor(Math.random() * 0x1_00_00_00)
      .toString(16)
      .padStart(6, '0')
  );
}

export function randomHexColor(): number {
  return Math.floor(Math.random() * 0x1_00_00_00);
}

// inclusive: because math.random € [0, 1)
export function randomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomFloat(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export function randomItem<T>(collection: ArrayLike<T>): T {
  return collection[randomInt(0, collection.length - 1)];
}

export function* randomItems<T>(collection: ArrayLike<T>, count: number, repeat: boolean = false) {
  if (count > collection.length && !repeat) {
    throw new Error('count > collection.length');
  }
  if (repeat) {
    for (let i = 0; i < count; i++) {
      yield randomItem(collection);
    }
  } else {
    const indices = new Set<number>();
    while (indices.size < count) {
      indices.add(randomInt(0, collection.length - 1));
    }
    for (const i of indices) {
      yield collection[i];
    }
  }
}
