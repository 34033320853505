// eslint-disable-next-line no-restricted-syntax
import { DisplayObject } from 'pixi.js';
import { gsap } from 'gsap';
import { randomFloat } from './math/random';

export function playBump(
  display: DisplayObject,
  duration = 0.5,
  from = 0,
  to: number = display.scale.x,
  ease = 'Back.easeOut',
  yoyo = false
) {
  gsap.killTweensOf(display.scale);
  return gsap.fromTo(
    display.scale,
    { x: from, y: from },
    { x: to, y: to, duration: duration, ease: ease, yoyo: yoyo, repeat: yoyo ? 1 : 0 }
  );
}

export function shakePosition(
  obj: DisplayObject,
  x: number,
  y: number,
  maxForce: number,
  frequency: number,
  duration = 0.5,
  fade: 'in' | 'out' | 'none' = 'none',
  onUpdate: () => void = () => {},
  onComplete: () => void = () => {}
) {
  const originX = obj.x;
  const originY = obj.y;

  const t = gsap.timeline();
  const time = 1 / frequency;
  let force = fade === 'in' ? 0 : maxForce;
  const addForce = duration / time;

  for (let i = 0; i < duration; i += time) {
    switch (fade) {
      case 'in':
        force += maxForce / addForce;
        break;
      case 'out':
        force -= maxForce / addForce;
        break;
    }

    t.to(obj, {
      x: originX + x * randomFloat(-force, force),
      y: originY + y * randomFloat(-force, force),
      duration: time,
      ease: 'none',
      onUpdate: onUpdate,
      onComplete: onComplete,
    });
  }

  return t;
}

export function shakeRotation(
  obj: DisplayObject,
  angle: number,
  maxForce: number,
  frequency: number,
  duration = 0.5,
  fade: 'in' | 'out' | 'none' = 'none',
  onUpdate: () => void = () => {},
  onComplete: () => void = () => {}
) {
  const originAngle = obj.angle;

  const t = gsap.timeline();
  const time = 1 / frequency;
  let force = fade === 'in' ? 0 : maxForce;
  const addForce = duration / time;

  for (let i = 0; i < duration; i += time) {
    switch (fade) {
      case 'in':
        force += maxForce / addForce;
        break;
      case 'out':
        force -= maxForce / addForce;
        break;
    }

    t.to(obj, {
      angle: originAngle + angle * randomFloat(-force, force),
      duration: time,
      ease: 'none',
      onUpdate: onUpdate,
      onComplete: onComplete,
    });
  }

  return t;
}
